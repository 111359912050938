export const flattenDict = dict => {
  const obj = {};
  (dict || []).forEach(item => {
    obj[item.value] = {
      ...item,
    };
  });
  return obj;
};

const DictModel = {
  namespace: 'dict',
  state: {
    data: {},
    flattenData: {},
  },
};

export default DictModel;
