import moment from 'moment';
import _ from 'lodash';
import { message } from 'antd';
import request from '@/utils/request';
import { transformArchived } from '@/utils/utils';

const rates = [1, 10, 50, 100, 1000];

// 默认内容
const defaultState = {
  id: undefined,
  displayNode: false,
  data: [],
  selectedData: [],
  report: {},
  replayStatus: 'caret-right',
  rate: 1,
  day: moment()
    .startOf('d')
    .toJSON(),
  replay: 0,
  dataZoomValue: [0, 86399],
};

// 二分法查找时间节点
export function findIndex(time, arr) {
  let start = 0;
  let end = arr.length - 1;

  while (start <= end) {
    const mid = Math.floor(start + (end - start) / 2);
    const current = arr[mid];
    const next = arr[mid + 1];
    if (next && current && current[0] && next[0]) {
      const currentTime = current[0];
      const nextTime = next[0];
      if (currentTime <= time && nextTime > time) {
        return mid;
      }
      if (currentTime <= time && nextTime <= time) {
        start = mid + 1;
      } else {
        end = mid - 1;
      }
    } else {
      return mid;
    }
  }

  return 0;
}

const TraceModel = {
  namespace: 'trace',
  state: {
    ...defaultState,
  },
  reducers: {
    init(state, { initState }) {
      return {
        ...defaultState,
        report: state.report,
        id: state.id,
        ...initState,
      };
    },
    forward(state) {
      const idx = rates.findIndex(rate => rate === state.rate);
      if (idx !== -1 && idx + 1 < rates.length) {
        state.rate = rates[idx + 1];
      } else {
        message.info('不能再增加速率了');
      }
    },
    backward(state) {
      const idx = rates.findIndex(rate => rate === state.rate);
      if (idx !== -1 && idx - 1 >= 0) {
        state.rate = rates[idx - 1];
      } else {
        message.info('不能再减少速率了');
      }
    },
    set(state, { path, value }) {
      return _.set(state || {}, path, value);
    },
    update(state, { path, updater }) {
      return _.update(state || {}, path, updater);
    },
    assign(state, { values }) {
      return { ...state, ...values };
    },
  },
  effects: {
    *queryTrace(__, { select, call, put }) {
      const { id, day } = yield select(state => ({
        id: state.trace.id,
        day: state.trace.day,
      }));

      if (!id) {
        return;
      }

      // 历史归档轨迹
      if (moment().format('YYYY-MM-DD') !== moment(day).format('YYYY-MM-DD')) {
        const res = yield call(request.get, '/traces/archived', {
          params: {
            entityId: id,
            date: moment(day).format('YYYY-MM-DD'),
          },
        });

        const url = _.get(res, 'data.url');

        if (url) {
          const reportRes = yield call(request.get, url, { timeout: 10000 });
          yield put({
            type: 'set',
            path: 'data',
            value: [
              {
                entityId: id,
                data:
                  reportRes.statusText === 'OK'
                    ? transformArchived(reportRes.data, res.data.splitThrehold)
                    : [],
              },
            ],
          });
        }
      } else {
        // 当天的轨迹
        const res = yield call(request.get, '/traces', {
          timeout: 10000,
          params: {
            entityIds: id,
            startTime: day,
            // compress: true,
            endTime: moment(day)
              .endOf('d')
              .toJSON(),
          },
        });

        let data = _.get(res, 'data', []);
        if (!_.isArray(data)) {
          return;
        }
        data = _.map(data, item => {
          if (_.isArray(item.data)) {
            item.data = _.map(item.data, trace => {
              if (_.isArray(trace)) {
                trace = _.map(trace, pos => {
                  if (pos[0]) {
                    pos[0] = new Date(pos[0]).getTime();
                  }
                  return pos;
                });
              }
              return trace;
            });
          }

          return item;
        });

        yield put({
          type: 'set',
          path: 'data',
          value:
            res.statusText === 'OK'
              ? data
              : [
                  {
                    entityId: id,
                    data: [],
                  },
                ],
        });
      }

      yield put({ type: 'changeTraceSelectedData' });
    },
    *queryTraceReport(__, { select, call, put }) {
      const { id } = yield select(state => ({
        id: state.trace.id,
      }));

      if (id) {
        const res = yield call(request.get, '/traces/report', {
          timeout: 10000,
          params: {
            entityIds: id,
            time: `${moment()
              .subtract(180, 'd')
              .format('YYYYMMDD')},${moment().format('YYYYMMDD')}`,
          },
        });

        if (res.statusText === 'OK') {
          yield put({
            type: 'set',
            path: 'report',
            value: _.get(res, 'data', {}),
          });
        }
      }
    },
    *changeTraceSelectedData(__, { select, put }) {
      const { day, data, dataZoomValue } = yield select(state => ({
        day: state.trace.day,
        data: state.trace.data,
        dataZoomValue: state.trace.dataZoomValue,
      }));

      const nextData = [];

      (data || []).forEach(item => {
        const nextItemData = [];

        if (item.data && Array.isArray(item.data)) {
          (item.data || []).forEach(dataItem => {
            const start = findIndex(
              moment(day)
                .startOf('d')
                .set('s', dataZoomValue[0])
                .valueOf(),
              dataItem,
            );
            const end = findIndex(
              moment(day)
                .startOf('d')
                .set('s', dataZoomValue[1])
                .valueOf(),
              dataItem,
            );
            const nextDataItem = dataItem.slice(start, end);
            if (nextDataItem.length > 0) {
              nextItemData.push(nextDataItem);
            }
          });

          nextData.push({
            id: item.entityId,
            name: item.entityName,
            data: nextItemData,
          });
        }
      });

      yield put({
        type: 'assign',
        values: {
          selectedData: nextData,
          replayStatus: 'caret-right',
          replay: dataZoomValue[0],
        },
      });
    },
    *downloadTraceData({ payload = 'gcj02' }, { select, call }) {
      const { id, day } = yield select(state => ({
        id: state.trace.id,
        day: state.trace.day,
      }));

      if (moment().format('YYYY-MM-DD') !== moment(day).format('YYYY-MM-DD')) {
        const res = yield call(request.get, '/traces/archived', {
          params: {
            entityId: id,
            date: moment(day).format('YYYY-MM-DD'),
          },
        });
        const url = _.get(res, 'data.url');
        if (url) {
          window.open(url);
        } else {
          message.error('无数据');
        }
      } else {
        const res = yield request.get('/temptoken');
        // eslint-disable-next-line no-underscore-dangle
        if (res.statusText === 'OK' && res.data && res.data.__t) {
          window.open(
            `/mercatorapi/traces/export?__t=${
              // eslint-disable-next-line no-underscore-dangle
              res.data.__t
            }&coordinateSystem=${payload}&entityIds=${id}&startTime=${moment(day)
              .startOf('d')
              .toJSON()}&endTime=${moment(day)
              .endOf('d')
              .toJSON()}`,
          );
        } else {
          message.error('下载错误，请重试');
        }
      }
    },
  },
};

export default TraceModel;
