// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelDict0 from '/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/models/dict.ts';
import ModelEntity1 from '/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/models/entity.ts';
import ModelFence2 from '/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/models/fence.ts';
import ModelGlobal3 from '/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/models/global.ts';
import ModelLogin4 from '/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/models/login.ts';
import ModelSetting5 from '/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/models/setting.ts';
import ModelTrace6 from '/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/models/trace.ts';
import ModelUser7 from '/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/models/user.ts';
import dvaImmer, { enableES5, enableAllPlugins } from '/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/node_modules/dva-immer/dist/index.js';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  app.use(dvaImmer());
  enableES5();
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'dict', ...ModelDict0 });
app.model({ namespace: 'entity', ...ModelEntity1 });
app.model({ namespace: 'fence', ...ModelFence2 });
app.model({ namespace: 'global', ...ModelGlobal3 });
app.model({ namespace: 'login', ...ModelLogin4 });
app.model({ namespace: 'setting', ...ModelSetting5 });
app.model({ namespace: 'trace', ...ModelTrace6 });
app.model({ namespace: 'user', ...ModelUser7 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
