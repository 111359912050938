import { message } from 'antd';
import { queryCurrentUser, updateCurrentUser, query as queryUsers } from '@/services/user';
import { setAuthority } from '@/utils/authority';
import { parseJson } from '@/utils/utils';

const UserModel = {
  namespace: 'user',
  state: {
    currentUser: {},
  },
  effects: {
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers);
      yield put({
        type: 'save',
        payload: response,
      });
    },
    *fetchCurrent(_, { call, put }) {
      const response = yield call(queryCurrentUser);
      if (response) {
        if (response.permissions) {
          setAuthority(response.permissions);
        }
        yield put({
          type: 'saveCurrentUser',
          payload: response,
        });
      }
    },
    *updateCurrent({ payload }, { call, put }) {
      const response = yield call(updateCurrentUser, payload);
      if (response.statusText === 'OK') {
        message.success('系统配置更新成功');
        yield put({
          type: 'updateCurrentUser',
          payload,
        });
      }
    },
  },
  reducers: {
    saveCurrentUser(state, { payload }) {
      return { ...state, currentUser: payload || {} };
    },
    updateCurrentUser(state, { payload }) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...payload,
          pointConfig: parseJson(payload?.pointConfig),
        },
      };
    },
    changeNotifyCount(
      state = {
        currentUser: {},
      },
      action,
    ) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};
export default UserModel;
