import { message } from 'antd';
import request from '@/utils/request';
import { flattenDict } from '@/models/dict';

message.config({ maxCount: 3 });

export const dva = {
  config: {
    initialState: {
      dict: {
        data: {
          bizTypes: [],
        },
        flattenData: {
          bizTypes: {},
        },
      },
    },
  },
};

// 从服务器获取字典
async function fetchDict() {
  const dict: any = {
    data: {
      bizTypes: [],
    },
    flattenData: {
      bizTypes: {},
    },
  };
  const res = await request.get('/enums');
  if (res.statusText === 'OK' && res.data) {
    dict.data = res.data;
    (Object.keys(res.data) || []).forEach(key => {
      const element = res.data[key];
      dict.flattenData[key] = flattenDict(element);
    });
  }
  dva.config.initialState.dict = dict;
}

// 获取业务类别
async function fetchBizTypes() {
  const res = await request.get('bizTypes');
  if (res.statusText === 'OK' && res.data && Array.isArray(res.data.data)) {
    const data = res.data.data.map((item: any) => {
      if (!item.color) {
        console.log('业务类型不存在color属性', item);
      }
      if (!item.iconType) {
        console.log('业务类型不存在iconType属性', item);
      }
      return {
        label: item.name,
        value: item.id,
        protocol: item.protocol,
        color: item.color || '#13c2c2',
        iconType: item.iconType || 'default',
      };
    });
    dva.config.initialState.dict.data.bizTypes = data;
    dva.config.initialState.dict.flattenData.bizTypes = flattenDict(data);
  }
}

// 系统初始化
async function initialize() {
  await fetchDict();
  await fetchBizTypes();
}

export async function render(oldRender: any) {
  await initialize();
  oldRender();

  // if (isValidBrowser) {
  //   oldRender();
  // } else {
  //   message.error('浏览器版本太低，无法使用本服务，请升级您的浏览器');
  // }
}
