import request from '@/utils/request';

export async function query(params = {}) {
  return request('/fences', {
    params: {
      withGeometry: true,
      status: 1,
      limit: 10000,
      ...params,
    },
  });
}
