import _ from 'lodash';
import { query } from '@/services/entity';

const EntityModel = {
  namespace: 'entity',
  state: {
    total: 0,
    onlineTotal: 0,
    offlineTotal: 0,
    neverTotal: 0,
    data: [],
    /**
     * 左侧选中的类型：999-全部，1-在线，2-离线，3-从未链接
     */
    activeStatus: 999,
    searchResult: undefined,
    entityGroup: {},
    refreshInterval: 5,
    dataRefreshInterval: 5,
    activeKey: undefined,
    current: {
      visible: false,
      data: undefined,
    },
  },
  reducers: {
    set(state, { payload }) {
      return { ...state, ...payload };
    },
    setActiveKey(state, { payload }) {
      state.activeKey = payload;
    },
    setRefreshInterval(state, { payload }) {
      state.refreshInterval = payload;
    },
    setDataRefreshInterval(state, { payload }) {
      state.dataRefreshInterval = payload;
    },
    setCurrent(state, { payload }) {
      const { visible, data } = payload || {};
      if (typeof visible === 'boolean') {
        state.current = { visible, data };
      }
      if (data) {
        state.current.data = data;
      }
    },
    setRemoteData(state, { payload }) {
      state.current.data = payload.currentData;
      Object.assign(state, payload);
    },
    setRefreshData(state, { payload }) {
      (payload || []).forEach(item => {
        const index = state.data.findIndex(o => o.id === item.id);
        if (state.data && index !== -1) {
          state.data[index] = { ...state.data[index], ...item };
        }
        if (state.current.data && state.current.data.id === item.id) {
          state.current.data = { ...state.current.data, ...item };
        }
      });
    },
    update(state, { payload }) {
      _.update(state, payload.path, payload.updater);
    },
    search(state, { value, payload }) {
      let searchResult = {
        'search-result': {
          id: '1',
          name: '搜索结果',
          data: [],
        },
      };
      if (state) {
        if (value) {
          searchResult['search-result'].data = state.data.filter(v => {
            if (v.name && v.name.indexOf && v.name.indexOf(value) !== -1) {
              return true;
            }
            if (v.alias && v.alias.indexOf && v.alias.indexOf(value) !== -1) {
              return true;
            }
            return false;
          });

          searchResult['search-result'].data = searchResult['search-result'].data.sort((a, b) => {
            if (a.status === 1 && b.status !== 1) {
              return -1;
            }
            if (a.status !== 1 && b.status === 1) {
              return 1;
            }
            return 0;
          });
        }

        if (!value) {
          searchResult = undefined;
        }
      }
      return { ...state, activeKey: 'search-result', searchResult, ...payload };
    },
  },
  effects: {
    *fetch(__, { put, call, select }) {
      const dict = yield select(state => state.dict);
      const current = yield select(state => state.entity.current);
      const respone = yield call(query);
      if (respone.data && Array.isArray(respone.data.data)) {
        const { data } = respone.data;

        const entityGroup = {};

        let onlineTotal = 0;
        let offlineTotal = 0;
        let neverTotal = 0;
        let currentData;

        // 统计和分组
        (data || []).forEach(item => {
          if (current.data && current.data.id) {
            if (item.id === current.data.id) {
              currentData = item;
            }
          }

          if (!entityGroup[item.bid]) {
            entityGroup[item.bid] = {
              id: item.bid,
              name: _.get(dict, `flattenData.bizTypes.${item.bid}.label`, '-'),
              data: [item],
            };
          } else {
            entityGroup[item.bid].data.push(item);
          }

          if (item.status === 1) {
            // 在线
            onlineTotal += 1;
          } else if (item.status === 3) {
            // 从未连接
            neverTotal += 1;
          } else {
            // 离线
            offlineTotal += 1;
          }
        });

        // 重新排序
        (Object.keys(entityGroup) || []).forEach(key => {
          entityGroup[key].data = entityGroup[key].data.sort((a, b) => {
            if (a.status === 1 && b.status !== 1) {
              return -1;
            }
            if (a.status !== 1 && b.status === 1) {
              return 1;
            }
            return 0;
          });
        });

        yield put({
          type: 'setRemoteData',
          payload: {
            currentData,
            data,
            entityGroup,
            total: data.length,
            onlineTotal,
            offlineTotal,
            neverTotal,
          },
        });
      }
    },
    *refresh(__, { put, call, select }) {
      const refreshInterval = yield select(state => state.entity.refreshInterval);
      const respone = yield call(query, { refreshInterval, select: 'id' });
      const data = _.get(respone, 'data.data', []);

      yield put({
        type: 'setRefreshData',
        payload: data,
      });
    },
  },
};

export default EntityModel;
