import request from '@/utils/request';

export async function query(params = {}) {
  return request('/entities', {
    params: {
      withPointDetail: true,
      withTCPStatus: true,
      limit: 10000,
      ...params,
    },
  });
}

export async function auth(dsks) {
  return request('/ggaentities', {
    method: 'post',
    data: {
      dsks,
    },
  });
}

export async function cancelAuth(dsks) {
  return request('/ggaentities', {
    method: 'delete',
    data: {
      dsks,
    },
  });
}

export async function resetAuthCode(eid) {
  return request(`/jt808entities/authcode/${eid}`, {
    method: 'delete',
  });
}
