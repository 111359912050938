import axios from 'axios';
import { notification } from 'antd';
import { PASSPORT } from '@/utils/utils';

axios.defaults.baseURL = '/mercatorapi';
axios.defaults.timeout = 10000;

axios.interceptors.response.use(
  response => {
    response.statusText = 'OK';
    return response;
  },
  error => {
    const { status, data } = error.response || {};
    if (status === 401) {
      window.location.href = `${PASSPORT}?redirect_url=${encodeURIComponent(window.location.href)}`;
    } else if (status >= 400 && status < 500) {
      if (status && data?.msg) {
        notification.destroy();
        notification.error({
          message: '提示',
          description: data.msg,
        });
      }
    } else {
      notification.destroy();
      notification.info({ message: '提示', description: '接口服务异常，请刷新页面重试' });
    }
    return error.response || {};
  },
);

export default axios;
