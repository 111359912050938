import { parseJson } from '@/utils/utils';
import { reloadAuthorized } from '@/utils/Authorized';
// use localStorage to store the authority info, which might be sent from server in actual project.

const CACHE_KEY = 'mercator-authority';

export function getAuthority(str?: string) {
  // authorityString could be admin, "admin", ["admin"]
  const authorityString = str || (window.localStorage.getItem(CACHE_KEY) as string);

  const authority = parseJson(authorityString);

  if (authority && typeof authority === 'string') {
    return [authority];
  }

  // preview.pro.ant.design only do not use in your production.
  // preview.pro.ant.design 专用环境变量，请不要在你的项目中使用它。
  if (!authority && process.env.ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return ['admin'];
  }

  return authority;
}

export function setAuthority(authority: string | string[]) {
  const proAuthority = typeof authority === 'string' ? [authority] : authority;
  localStorage.setItem(CACHE_KEY, JSON.stringify(proAuthority)); // auto reload

  reloadAuthorized();
}
