/* eslint-disable no-param-reassign */
import { parse } from 'querystring';
import pathRegexp from 'path-to-regexp';
import _ from 'lodash';
/* eslint no-useless-escape:0 import/prefer-default-export:0 */

export const COMMON_HOST = window.location.hostname.replace(/^[\w-]+|\.my/g, '');

export const PASSPORT = `//passport${COMMON_HOST}`;

const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export const isUrl = (path: string) => reg.test(path);

export const isAntDesignPro = () => {
  if (process.env.ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }

  return window.location.hostname === 'preview.pro.ant.design';
}; // 给官方演示站点用，用于关闭真实开发环境不需要使用的特性

export const isAntDesignProOrDev = () => {
  const { NODE_ENV } = process.env;

  if (NODE_ENV === 'development') {
    return true;
  }

  return isAntDesignPro();
};

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

/**
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */
export const getAuthorityFromRouter = (router = [], pathname: string) => {
  const authority = router.find(({ path }) => path && pathRegexp(path).exec(pathname));
  if (authority) return authority;
  return undefined;
};

// 转换数据
export function transformArchived(data: string, threhold = 30) {
  let prev: number;
  // console.time('transformArchived');
  try {
    return _.reduce(
      _.slice(
        _.split(data, '\n').map(item =>
          _.split(item, ',').map((p, idx) => {
            if (idx > 0) {
              return parseFloat(p);
            }
            return new Date(p).getTime();
          }),
        ),
        1,
      ),
      (result: number[][], item: number[]) => {
        if (!prev) {
          [prev] = item;
          // 去掉 0 点
          if (item[1] && item[2]) {
            const { mglng, mglat } = wgs84togcj02(item[1], item[2]);
            item[1] = mglng;
            item[2] = mglat;
            result[result.length - 1].push(item);
          }
          return result;
        }
        if (item[0] - prev > threhold * 1000) {
          result.push([]);
        }
        [prev] = item;
        // 去掉 0 点
        if (item[1] && item[2]) {
          const { mglng, mglat } = wgs84togcj02(item[1], item[2]);
          item[1] = mglng;
          item[2] = mglat;
          result[result.length - 1].push(item);
        }
        return result;
      },
      [[]],
    );
  } catch (error) {
    console.log('数据转换失败', error);
    return [];
  }
}

// π
const pi = 3.1415926535897932384626;
// 长半轴
const a = 6378245.0;
// 扁率
const ee = 0.00669342162296594323;

/**
 * 判断是否在国内，不在国内不做偏移
 *
 * @param lng
 * @param lat
 * @return
 */
export function outOfChina(lng: number, lat: number) {
  if (lng < 72.004 || lng > 137.8347) {
    return true;
  }
  if (lat < 0.8293 || lat > 55.8271) {
    return true;
  }
  return false;
}

/**
 * 纬度转换
 *
 * @param lng
 * @param lat
 * @return
 */
export function transformLat(lng: number, lat: number) {
  let ret =
    -100.0 +
    2.0 * lng +
    3.0 * lat +
    0.2 * lat * lat +
    0.1 * lng * lat +
    0.2 * Math.sqrt(Math.abs(lng));
  ret += ((20.0 * Math.sin(6.0 * lng * pi) + 20.0 * Math.sin(2.0 * lng * pi)) * 2.0) / 3.0;
  ret += ((20.0 * Math.sin(lat * pi) + 40.0 * Math.sin((lat / 3.0) * pi)) * 2.0) / 3.0;
  ret += ((160.0 * Math.sin((lat / 12.0) * pi) + 320 * Math.sin((lat * pi) / 30.0)) * 2.0) / 3.0;
  return ret;
}

/**
 * 经度转换
 *
 * @param lng
 * @param lat
 * @return
 */
export function transformLng(lng: number, lat: number) {
  let ret =
    300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
  ret += ((20.0 * Math.sin(6.0 * lng * pi) + 20.0 * Math.sin(2.0 * lng * pi)) * 2.0) / 3.0;
  ret += ((20.0 * Math.sin(lng * pi) + 40.0 * Math.sin((lng / 3.0) * pi)) * 2.0) / 3.0;
  ret += ((150.0 * Math.sin((lng / 12.0) * pi) + 300.0 * Math.sin((lng / 30.0) * pi)) * 2.0) / 3.0;
  return ret;
}

/**
 * WGS84转GCJ02(火星坐标系)
 *
 * @param lng WGS84坐标系的经度
 * @param lat WGS84坐标系的纬度
 * @return 火星坐标数组
 */
export function wgs84togcj02(lng: number, lat: number) {
  if (outOfChina(lng, lat)) {
    return { mglng: lng, mglat: lat };
  }
  let dlat = transformLat(lng - 105.0, lat - 35.0);
  let dlng = transformLng(lng - 105.0, lat - 35.0);
  const radlat = (lat / 180.0) * pi;
  let magic = Math.sin(radlat);
  magic = 1 - ee * magic * magic;
  const sqrtmagic = Math.sqrt(magic);
  dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * pi);
  dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * pi);
  const mglat = lat + dlat;
  const mglng = lng + dlng;
  return { mglng, mglat };
}

/**
 * 地图上显示的有效点：在线的点 + 点击的
 */
export function validEnity(list: any[], current: any) {
  return list.filter(
    item =>
      item.lon &&
      item.lat &&
      (item.status === 1 || (current?.visible && current?.data?.id === item.id)),
  );
}

/**
 * 解析 Json 字符串
 */
export function parseJson(str?: string) {
  if (!str || typeof str !== 'string') {
    return str;
  }
  try {
    return JSON.parse(str);
  } catch (error) {
    return str;
  }
}
