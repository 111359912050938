import _ from 'lodash';
import { query } from '@/services/fence';

const FenceModel = {
  namespace: 'fence',
  state: {
    data: [],
  },
  reducers: {
    set(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *fetch(__, { put, call }) {
      const response = yield call(query);
      const data = _.get(response, 'data.data', []);
      yield put({
        type: 'set',
        payload: {
          data,
        },
      });
    },
  },
};

export default FenceModel;
