import _ from 'lodash';
import request from '@/utils/request';
import avatar from '@/assets/avatar.svg';
import { parseJson } from '@/utils/utils';

export async function query() {
  return request('/api/users');
}

export async function queryCurrentUser() {
  return request('/me').then(res => {
    if (res.statusText === 'OK') {
      return {
        name: res.data.username,
        avatar,
        status: res.data.status || 'INIT',
        userid: 'current',
        permissions: res.data.permissions,
        defaultLayer: _.get(res, 'data.defaultLayer', 'Vector'),
        useMapBounds: _.get(res, 'data.useMapBounds', false), // boolean
        mapBounds: _.get(res, 'data.mapBounds', []), // bounds
        layers: _.get(res, 'data.layers', []),
        pushInfo: _.get(res, 'data.pushInfo', {}),
        pointConfig: parseJson(_.get(res, 'data.pointConfig')) || {},
        traceThreshold: _.get(res, 'data.traceThreshold', 60), // 为 0 时，不开启行程拆分
        reportCalculateErrorPoint: res.data.reportCalculateErrorPoint,
        pageShowErrorPoint: res.data.pageShowErrorPoint,
        openApiReturnErrorPoint: res.data.openApiReturnErrorPoint,
      };
    }
    return null;
  });
}

export async function updateCurrentUser(params: any) {
  return request('/me', {
    method: 'PUT',
    data: params,
  });
}

export async function queryNotices() {
  return request('/api/notices');
}

export async function feedApi(params: any) {
  return request('/feedback', {
    method: 'POST',
    data: params,
  });
}
