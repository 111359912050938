// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/layouts/SecurityLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/layouts/BlankLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/detail/trace/:id",
            "name": "trace-detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__detail__trace' */'/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/pages/detail/trace'), loading: LoadingComponent}),
            "authority": [
              "trace:r"
            ],
            "title": "查看轨迹",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/detail/entity/:id",
            "name": "entity-detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__detail__entity' */'/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/pages/detail/entity'), loading: LoadingComponent}),
            "title": "实体详情",
            "authority": [
              "entity:r"
            ],
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/layouts/BasicLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "redirect": "/monitor",
            "exact": true
          },
          {
            "path": "/monitor",
            "name": "monitor",
            "title": "地图监控",
            "authority": [
              "entity:r"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitor' */'/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/pages/monitor'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/entity",
            "name": "entity",
            "title": "实体管理",
            "authority": [
              "entity:r"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__entity' */'/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/pages/entity'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/fence",
            "name": "fence",
            "title": "围栏管理",
            "authority": [
              "fence:r"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fence' */'/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/pages/fence'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/config",
            "name": "config",
            "title": "系统配置",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__config' */'/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/pages/config'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "https://help.qxwz.com/240000",
            "name": "help",
            "target": "__blank",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/GN-Build@2/workspace/program/husky-h5-2.9.4/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
